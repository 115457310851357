import _Uppy from "./Uppy";
import _UIPlugin from "./UIPlugin";
import _BasePlugin from "./BasePlugin";
import _loggers from "./loggers";
var exports = {};
const Uppy = _Uppy;
const UIPlugin = _UIPlugin;
const BasePlugin = _BasePlugin;
const {
  debugLogger
} = _loggers;
exports = Uppy;
exports.Uppy = Uppy;
exports.UIPlugin = UIPlugin;
exports.BasePlugin = BasePlugin;
exports.debugLogger = debugLogger;
export default exports;
const _Uppy2 = exports.Uppy,
      _UIPlugin2 = exports.UIPlugin,
      _BasePlugin2 = exports.BasePlugin,
      _debugLogger = exports.debugLogger;
export { _Uppy2 as Uppy, _UIPlugin2 as UIPlugin, _BasePlugin2 as BasePlugin, _debugLogger as debugLogger };